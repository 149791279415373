// SETTINGS

$break: 	giant; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for quickbar
$barPos: 	top; // position (top, bottom)
$barHide: 	false; 	// if bar hides on scroll mobile (true, false)
$navHover:	true;	// hover functionality for desktop navigation
$time: 		0.3s; 	// transition for some stuff

// MOBILE NAVIGATION

@include breakpoint($break, max) {

	#navigation {
		transition:transform $time;
		background-color:$light;

		.info {
			padding: rem($baseGap*2) rem($baseGap);

			a.btn {
				display: none;
			}
			span.mail {
				display: block;
				margin-bottom: rem(10px);
			}
			span.call {
				display: block;
				margin-bottom: rem($baseGap);
			}
		}

		.hr {
			display: none;
		}

		ul {
			background-color:$light;

			li {
				display: flex;
				width: 100%;

				a, span {
					align-items: center;
					border-bottom: rem(1px) solid rgba($dark,0.1);
					color:$dark;
					display: flex;
					font-size:rem(16px);
					justify-content: space-between;
					line-height: rem(24px);
					padding:rem(15px) rem($baseGap);
					text-decoration: none;
					width: 100%;
					transition:background $time, color $time;

					&.subToggler {

						&:after {
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
						};
					}
				};

				&.navBack {

					span {
						justify-content: flex-start;

						&:before {
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
							transform:rotate(180deg) translateY(rem(1px));
							margin-right: rem(7px);
						}
					}
				}

				.sub {
					&:before {
						padding:rem(15px) rem($baseGap);
						font-size: rem(19px);
						display: block;
						content:attr(data-parent-name);
						background:$medium;
					}
				}

				// active status for all elements

				body.blog &.blog {
					background:$primary;
					color:$light;
				}

				&.active > a, &.active > span, a.active, span.active {
					background:$primary;
					color:$light;
				}
			}
		}

		//////////////////////////////////////////////////////////////////////////////////////////// 
		//essential styles for an working mobile subnavigation
		// do not change this, unless you know what you do
		
		min-width:90%;
		max-width: 100%;
		@include breakpoint(tiny) {
			max-width: rem(432px);
			min-width: rem(432px);
		}
		overflow:hidden;
		position:fixed;
		right:0;
	 	z-index:1002;

	 	@if $barPos == "top" {
	 		bottom:0;
			top:rem($barHeight);
		} @else if $barPos == "bottom" {
			top: 0;
			bottom:rem($barHeight);
		}

	 	nav {
	 		overflow: hidden;
    		overflow-y: auto;
    		height:calc(100vh - #{rem($barHeight*3)});

    		ul {
    			padding-bottom: rem(200px); // fix for mobile phones with a toolbar at the end
    			width:100%;

    			&.sub {
					position:absolute;
					left:0;
					top:0;
					transform:translateX(100%);
					height: 100%;
					transition:transform $time;
					padding: 0 rem($baseGap);
					padding-bottom: rem(150px);
				}

				&.current {
					transform:translateX(0%);

					&.open {
						z-index: 1;
						overflow:hidden;
						overflow-y:auto;
						-webkit-overflow-scrolling:touch;
					}
				}
    		}
	 	}

	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	}

	html.hiddenScroll {
		overflow:hidden;
	}
}

// DESKTOP NAVIGATION

@include breakpoint($break) {

	#navigation {
		position: relative;
		z-index: 100;
		padding: rem(20px) 0;
		width: 100%;
		background: $light;
		@include breakpoint(huge) {
			padding: rem(25px) 0;
			.scroll & {
				padding: rem(20px) 0;
			}
		}

		.info {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			>span {
				margin-left: rem(40px);
				@include breakpoint(full) {
					margin-left: rem(60px);
				}
			}
			.social {
				margin-right: 0;
			}
			span.mail {
				margin-right: rem(15px);
				@include breakpoint(full) {
					margin-right: rem(30px);
				}
			}
		}

		.hr {
			margin: rem(20px) 0;
			.scroll & {
				margin: rem(15px) 0;
			}
			@include breakpoint(huge) {
				margin: rem(25px) 0;
			}
		}

		nav {

			.navi {
				display: flex;
				justify-content: space-between;

				// only first level <li>

				> li {
					&.datenschutzerklaerung, &.impressum {
						display: none;
					}
					> a, > span {
						padding:rem(7px) 0;

						&:before {
							position: absolute;
							top:120%;
							width: rem(14px);
							height: rem(14px);
							border-radius: 100%;
							background:$primary;
							content:'';
							opacity: 0;
							left: calc(50% - 6px);
							visibility: hidden;
							transition:opacity $time, visibility $time, top $time;
							z-index: -1;
						}
					}
					body.blog &.blog {
						&:after {
							position: absolute;
							top:50%;
							width: rem(14px);
							height: rem(14px);
							border-radius: 100%;
							background:$primary;
							content:'';
							opacity: 1;
							left: calc(50% - 6px);
							visibility: visible;
							transition:opacity $time, visibility $time, top $time;
							z-index: -1;
						}
						a {
							&:hover {
								&:before {
									display: none;
								}
							}
						}
					}

					// active and hover status for all first level elements

					&.active > a, &.active > span, > a.active, > span.active, &:hover > a, &:hover > span {
						
						&:before {
							top:50%;
							opacity: 1;
							visibility: visible;
						}
					}

					@if $navHover {

				 		body.isDesktop & {

							&:hover {

								> .sub {
									top:100%;
									opacity: 1;
									visibility: visible;
									pointer-events: auto;
								}
							}

							.navBack {
								display: none;
							}
						}

					};
				}

				li {
					display: flex;
					align-items: center;
					position: relative;

					a, span {
						white-space: nowrap;
						color:$dark;
						display: flex;
						align-items: center;
						text-decoration: none;
						cursor: pointer;
						font-size:rem(16px);
						line-height: 100%;
						position: relative;
						width: 100%;

						&.subToggler {

							&:after {
								font-family: $iconFont;
								content: map-get($iconMap, angle-right);
								transform:rotate(90deg) translateX(2px);
								margin-left: rem(4px);
							};
						}
					}

					&.navBack {
						text-align: center;

						span {
							display: none;
						}

						&:before {
							cursor: pointer;
							width: 100%;
							font-family: $iconFont;
							content: map-get($iconMap, times);
							display: block;
							font-size: rem(16px);
							padding:rem(5px) rem($baseGap);
							background:$medium;
							transition:background $time;
						}

						&:hover {

							&:before {
								background:darken($medium,10%);
							}
						}
					}			

					.sub {
						background:$light;
						position: absolute;
						top:120%;
						left:-40%;
						min-width: 100%;
						opacity: 0;
						visibility: hidden;
						pointer-events: none;
						transition:opacity $time, visibility $time, top $time;
						box-shadow:0 0 rem(10px) rgba(black,0.05);

						li {

							a, span {
								transition:all $time;
								padding:rem(15px) rem($baseGap);
							}

							// active and hover status for all sub a elements

							&.active > a, &.active > span, a.active, span.active, &:hover > a, &:hover > span, a:hover, span:hover {
								background:$primary;
								color:$light;
							}

							@if $navHover {

						 		body.isDesktop & {

									&:hover {

										> .sub {
											top:0%;
											opacity: 1;
											visibility: visible;
											pointer-events: auto;
										}
									}

									.navBack {
										display: none;
									}
								}

							};
						}

						&.current {
							top:100%;
							opacity: 1;
							visibility: visible;
							pointer-events: auto;
						}

						&.open {
							top:100%;
						}

						.sub {
							top:20%;
							left:100%;

							&.current, &.open {
								top:0;
							}
						}
					}
				}
			}
		}
	}
}

// MOBILE QUICKBAR

@include breakpoint($break, max) {

	#quickbar {
		box-shadow:0 0 rem(10px) rgba(black,0.1);
		height:rem($barHeight);
		background-color:$secondary;
		display:flex;
		justify-content:space-between;
		align-items:center;
		z-index:1000;
		position:fixed;
		left:0;
		width:100%;
		padding:0 rem($baseGap);
		transform:translateY(0%);
		transition:transform $time;

		.btn {
			font-weight: 400;
			padding: 0.2rem 0.7rem;
			letter-spacing: 0.05em;
		}

		@if $barPos == "top" {
			top: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrollingDown & {
					transform: translateY(-100%);
				}
			}
		} @else if $barPos == "bottom" {
			bottom: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrollingDown & {
					transform: translateY(100%);
				}
			}
		}

		label[for="naviToggled"] {
			display:block;
			cursor:pointer;
			width:rem(20px);
			height:rem(20px);
			position:relative;
			overflow:hidden;

			&:after{
				content:"";
				position:fixed;
				left:0;
				width:200vw;
				height:200vh;
				display:block;
				background-color:rgba($dark,0.9);
				z-index:-1;
				transition: opacity $time, visibility $time;

				@if $barPos == "top" {
					top:rem($barHeight);
				} @else if $barPos == "bottom" {
					bottom:rem($barHeight);
				}
			}

			span {
				transition:0.4s linear;
				text-indent:-9999px;
				width:0;
				top:50%;
				transform:translate(-50%, -50%);

				&:before, &:after {
					top:50%;
					width:rem(20px);
					transition:top $time ease-in-out, transform $time 0.2s linear;
				}

				&:before {
					transform:translate(-50%, -50%) rotate(45deg);
				};

				&:after {
					transform:translate(-50%, -50%) rotate(-45deg)
				};
			}

			span, span:before, span:after {
				content:"";
				position:absolute;
				display:block;
				background:$light;
				height:2px;
				left:50%;
				transform-origin:center
			};
		};
	};
	
	#naviToggled {

		&:not(:checked) {

	 		& ~ * #navigation,
	 		& ~ #navigation {
				transform:translateX(100%);
	 		};

	 		& ~ #quickbar {
				
				label[for="naviToggled"] {

					span {
						width:20px;

						&:before, &:after {
							transform:translate(-50%, -50%);
							transition:top $time 0.2s ease-in-out, transform $time linear;
						};

						&:before {
							top:calc(50% - 5px);
						};

						&:after {
							top:calc(50% + 5px);
						};
					}

					&:after {
						opacity:0;
		 				visibility:hidden
					};
				}

	 		};
	 		
	 	};
	}
}

#naviToggled {
	display:none;
};

@include breakpoint($break) {
	#quickbar {
		display: none;
	}
}