* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
  opacity: 1 !important;
  &:not(.javascript) {
   * {
       transition: none!important;
     }
   }
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

body.leistungenResilienzTraining,
body.leistungenStaerkenCoaching {
  iframe,[data-iframe] {
    height: 400px;

    @include breakpoint(medium){
      height: 300px;
    }
    @include breakpoint(large){
      height: 400px;
    }
    @include breakpoint(huge){
      height: 290px;
    }
  }
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .marginBottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

.h2{
  hyphens: manual;
}

h1,
.h1 {
  display: block;
  font-family: $displayFont;
  font-size: rem(40px);
  line-height: 1.5em;
  color: $primary;
  font-weight: 400;
  span {
    display: block;
    font-family: $mainFont;
    font-size: rem(18px);
    line-height: 1.3em;
    letter-spacing: 0.1em;
    font-weight: 300;
    color: $dark;
  }
  @include breakpoint(giant) {
    font-size: rem($h1Size);
  }
  &.plugin {
    font-size: rem(40px);
    @include breakpoint(small) {
      font-size: rem(50px);
    }
    span {
      line-height: 1.6em;
      font-size: 16px;
      @include breakpoint(small) {
        font-size: rem(18px);
      }
    }
  }
}

h2,
.h2 {
  display: block;
  font-size: rem(25px);
  color: $primary;
  font-weight: 700;
  line-height: 1.3em;
  @include breakpoint(giant) {
    font-size: rem($h2Size);
  }
  &.plugin {
    margin-bottom: 0;
    span {
     font-weight: 300;
     color: $dark;
     display: block;
     font-size: 0.6em;
    }
  }
}

h3,
.h3 {
  display: block;
    font-size: rem(22px);
    color: $primary;
    font-weight: 700;
    line-height: 1.3em;
    @include breakpoint(giant) {
      font-size: rem($h3Size);
    }
}

h4,
.h4 {
  font-size: rem($h4Size);
}

h5,
.h5 {
  font-size: rem($h5Size);
}

h6,
.h6 {
  font-size: rem($h6Size);
}

/**
 * Links
 */
a {
  color: $secondary;
  word-wrap: break-word;
  transition: ease-in-out 0.3s;

  &:hover {
    color: $primary;
    .impressum #content & {
      color: $dark;
    }
  }
  &:focus {
    color: darken($primary, 50%);
  }
  &:active {
    color: darken($primary, 30%);
  }

  &.email, &.phone {
    color: $dark;
    &:hover {
      color: $primary;
    }
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $primary;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 3rem 0;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styledList {
    li {
      padding-left: rem(15px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-angle-right;
        text-indent: rem(-15px);
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styledList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($primary, 0.8);
      padding: rem(15px);
      a {
        &:hover {
          color: $secondary;
        }
      }
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.floatList {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styledList
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: center;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
    .blog &{
      margin-bottom: rem($baseGap*2);
    }
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($baseFontSize);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($baseGap) rem($baseGap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($baseGap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($baseGap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}

.small-space {
  margin-bottom: rem(40px);
}