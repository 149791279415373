/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 24px;

$h1Size: 70px;

$h2Size: 30px;

$h3Size: 26px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: Opens Sans, sans-serif;

$displayFont: "Stalemate", sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #61d3d4;
$secondary: #000333;
$light: #fff;
$medium: #f6f6f6;
$dark: #1f1f1f;
$border: #bbb;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	angle-right: "\ea1f", 
	angle-down: "\ea1d", 
	angle-up: "\e903", 
	exclamation: "\e90d", 
	minus: "\e90c", 
	plus: "\e90a", 
	times: "\e909", 
	gesture-phone-02: "\e9e2", 
	gesture-pad-05: "\e9dd", 
	gesture-pad-06: "\e9de", 
	sheet-text: "\eac8", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		center: (justify-content:center),
		last: (order:1),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6, 8),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 4, 6, 9),
		middle: (align-items:center),
	),
	large: (
		width: em(992px, $bpContext), 
		breakRight: (),
		cols: (2, 4, 5, 6, 7, 8),
		first: (order:-1),
		middle: (align-items:center),
	),
	giant: (
		width: em(1200px, $bpContext), 
		center: (justify-content:center),
		cols: (3, 4, 5, 6, 9, 10, 12),
		middle: (align-items:center),
		suffix: (1),
		prefix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (3, 4, 5, 6, 9, 10),
		middle: (align-items:center),
		prefix: (0, 1, 3),
		suffix: (1),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (5),
		suffix: (2),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

