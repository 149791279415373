.marginBottom {
  margin-bottom: rem($baseLineHeight);
}

.marginBottomBig {
  margin-bottom: rem(40px);
  @include breakpoint(small) {
    margin-bottom: rem(45px);
  }
  @include breakpoint(medium) {
    margin-bottom: rem(50px);
  }
  @include breakpoint(large) {
    margin-bottom: rem(65px);
  }
  @include breakpoint(giant) {
    margin-bottom: rem(85px);
  }
  @include breakpoint(huge) {
    margin-bottom: rem(100px);
  }
  @include breakpoint(full) {
    margin-bottom: rem(120px);
  }
}
.teaser{
  margin-bottom: 0.5rem!important;
}

.marginTopBig {
  margin-top: rem(50px);
  @include breakpoint(small) {
    margin-top: rem(55px);
  }
  @include breakpoint(medium) {
    margin-top: rem(60px);
  }
  @include breakpoint(large) {
    margin-top: rem(65px);
  }
  @include breakpoint(giant) {
    margin-top: rem(85px);
  }
  @include breakpoint(huge) {
    margin-top: rem(100px);
  }
  @include breakpoint(full) {
    margin-top: rem(120px);
  }
}

.paddingBox {
  padding: rem(30px) 0;
  @include breakpoint(giant) {
    padding: rem(60px) 0;
  }
}

.paddingBoxBig {
  padding: rem(30px) 0;
  @include breakpoint(small) {
    padding: rem(45px) 0;
  }
  @include breakpoint(medium) {
    padding: rem(50px) 0;
  }
  @include breakpoint(large) {
    padding: rem(65px) 0;
  }
  @include breakpoint(giant) {
    padding: rem(85px) 0;
  }
  @include breakpoint(huge) {
    padding: rem(100px) 0;
  }
  @include breakpoint(full) {
    padding: rem(120px) 0;
  }
}

.paddingTextRight {
  @include breakpoint(large) {
    padding-right: rem(30px);
    @include breakpoint(full) {
      padding-right: rem(50px);
    }
  }
}

.noPaddingRight {
  @include breakpoint(large) {
    padding-right: 0;
  }
}

.noPaddingLeft {
  @include breakpoint(large) {
    padding-left: 0;
  }
}


.highlight {
  color: $secondary;
}

.btn {
  @extend %animatedTransform;
  background: $primary;
  border: none;
  color: $light;
  cursor: pointer;
  display: inline-block;
  font-size: rem($baseFontSize);
  line-height: rem($baseLineHeight);
  padding: 0.6rem 0.9rem;
  border-radius: rem(35px);
  text-align: center;
  text-decoration: none;
  font-family: $mainFont;
  letter-spacing: 0.08em;
  font-weight: 700;
  @include breakpoint(tiny) {
    letter-spacing: 0.1em;
  }

  &:before {
    font-family: $iconFont;
    content: map-get($iconMap, angle-right);
    margin-right: rem(4px);
    transition: ease-in-out 0.4s;
  }

  &.pdf {
    &:before {
      font-family: $iconFont;
      content: map-get($iconMap, sheet-text);
      margin-right: rem(4px);
      transition: ease-in-out 0.4s;
    }
  }

  &:hover {
    background: lighten($secondary, 15%);
    color: $light;
    &:before {
      letter-spacing: 0em;
    }
  }
  &:active {
    background: lighten($dark, 30%);
    color: $light;
  }
  &:focus {
    background: lighten($dark, 45%);
    color: $light;
  }

  *[class^="icon"] {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.clear {
  clear: both;
  float: none;
}

.clearfix {
  @include pie-clearfix;
}

.hidden {
  display: none;
}

.hideText {
  @include hideText();
  display: inline-block;
  overflow: hidden;
  width: 0;
}

.floatLeft {
  float: left;
}

img.floatLeft {
  margin-right: 1rem;
}

.floatRight {
  float: right;
}

img.floatRight {
  margin-left: 1rem;
}

.fluid {
  height: auto;
  width: 100%;
}

.nonFluid {
  width: auto !important;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.wide {
  width: 100%;
  display: block;
}

/* 16:9 video resolutions */
.videoContainer {
  &:not(.videoTag) {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
      border: none;
      height: 100%;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
}
.googleMaps {
  @extend .videoContainer;

  @include breakpoint(medium) {
    height: rem(400px);
  }
}

body {
  &:before {
    $content: "default:" + $bpContext;
    width: str_length($content) * 6pt + $baseGap * 2;

    @each $point, $width in $gridMap {
      $content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

      @include breakpoint($point) {
        width: str_length($content) * 6pt + $baseGap * 2;
      }
    }

    content: "#{$content}";
    display: none !important; /* Prevent from displaying. */
  }

  &.devmode:before,
  &.debug:before {
    background: $alert;
    border-color: $alert;
    border-style: solid;
    border-width: 0 rem($baseGap);
    color: $light;
    display: block !important;
    font-family: Courier;
    font-size: 10pt;
    left: 0;
    line-height: 2.5;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    white-space: nowrap;
    z-index: 99999;
  }
  &:hover:before {
    opacity: 0.5;
    pointer-events: none;
  }
}

// Animations
%animatedTransform {
  transition: 0.3s;
}

.notificationArea {
  position: fixed;
  bottom: rem(40px);
  left: rem(40px);
  z-index: 999999;
  visibility: hidden;

  @include breakpoint(large) {
    left: rem(60px);
  }

  > :not(:last-child) {
    margin-bottom: $baseGap;
  }
  @media (max-width: 400px) {
    bottom: rem(75px);
  }

  .notification {
    position: relative;
    // transition: 300ms all ease-in-out;
    overflow: hidden;
    padding: 1em;
    background: $alert;
    font-size: rem(12px);
    line-height: rem(16px);
	  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
    width: rem(300px);
    max-width: calc(100vw - 80px);
    transform-origin: left center;
    max-height:9999999px;
    visibility: visible;

    @include breakpoint(large) {
      font-size:rem(16px);
      line-height: rem(22px);
    }

    &,
    a {
      color: $light;
    }

    &.success {
      background: #333;

      &, .close {
        color: #fff;
      }
    }
    &.error {
      background: $alert
    }
    &.success, &.error {
      p {
        margin-bottom: rem(5px);
      }
      p + ul {
        margin-top: rem(5px);
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      padding-top: 5px;
      padding-right: 8px;
      cursor: pointer;
      color: #fff;
      font-size: 1.2rem;
    }

    &.hide {
      opacity: 0;
      visibility: hidden;
      padding-top: 0;
      padding-bottom: 0;
      margin-left:-100px;
    }

    .privacy-fields {
      @media (max-height: 500px) {
        max-height: 25vh;
      }
    }
  }
}

html.inProgress {
  pointer-events: none;
  &:before {
    content:"";
    width: 100vw;
    height: 100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index:99999998
  }
  &:after {
    content:"";
    position: fixed;
    top: 0;
    height: 0;
    background: url('/images/layout/loading.svg') no-repeat center center;
    height: 100vh;
    width: 100vw;
    z-index:99999999;
    filter:invert(100%);
  }
}

// Socials
.social {
  display: inherit;
  @include breakpoint(giant) {
    margin-left: rem(40px);
  }
  @include breakpoint(huge) {
    margin-left: rem(60px);
  }
  a {
    transition: .3s;
    margin-right: rem(20px);
    &:last-child {
      margin-right: 0;
    }
    @include breakpoint(giant) {
      margin-right: 0;
      margin-left: rem(15px);
      &:first-child {
        margin-left: 0;
      }
    }
    &:hover {
       transform: scale(1.2);
    }
  }
 .fb {
   display: inline-block;
   width: rem(29px);
   height: rem(29px);
   background: url(../images/layout/fbook-color.svg) center center no-repeat;
   background-size: contain;
 }

 .insta {
   display: inline-block;
   width: rem(29px);
   height: rem(29px);
   background: url(../images/layout/instagram.svg) center center no-repeat;
   background-size: contain;
 }

 .linkin {
   display: inline-block;
   width: rem(31px);
   height: rem(29px);
   background: url(../images/layout/linkedin-color.png) center center no-repeat;
   background-size: contain;
 }

 .jameda {
   display: inline-block;
   width: rem(29px);
   height: rem(29px);
   background: url(../images/layout/jameda-icon.jpg) center center no-repeat;
   background-size: contain;
 }
}

// Styles Plugin
#_form_20_ {
  label {
    color: $light;
  }
  ._x68551912 {
    margin-bottom: rem($baseGap*2);
  }
}

#_form_14_, #_form_10_, #_form_16_ {
  ._x68551912 {
    margin-bottom: rem($baseGap*2);
  }
}

#_form_10_ {
  margin-bottom: rem($baseGap*3);
}

#_form_20_ ._form-branding {
  color:#fff; font-size:10px; clear:both; text-align:left; margin-top:30px; font-weight:100;
}
#_form_14_ ._form-branding, #_form_10_ ._form-branding, #_form_16_ ._form-branding {
  color:#000; font-size:10px; clear:both; text-align:left; margin-top:30px; font-weight:100;
}
#_form_20_ ._form-branding ._logo, #_form_14_ ._form-branding ._logo, #_form_10_ ._form-branding ._logo, #_form_16_ ._form-branding ._logo  {
  display:block; width:130px; height:14px; margin-top:6px; background-image:url('https://d226aj4ao1t61q.cloudfront.net/hh9ujqgv5_aclogo_li.png'); background-size:130px auto; background-repeat:no-repeat;
}
#_form_20_._dark ._form-branding, #_form_14_._dark ._form-branding, #_form_10_ ._form-branding, #_form_16_ ._form-branding {
  color:#333;
}
#_form_20_._dark ._form-branding ._logo, #_form_14_._dark ._form-branding ._logo, #_form_10_ ._form-branding ._logo, #_form_16_ ._form-branding ._logo {
  background-image:url('https://d226aj4ao1t61q.cloudfront.net/jftq2c8s_aclogo_dk.png');
}

.noBg {
  background: none;
}

.activeCamp {
  margin-bottom: rem($baseGap);
}

._form-thank-you  {
  color: $light;
}