// Use this for default Icomoon

@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: ("woff2","woff"),
    $style: normal
);

@include fontFaceV2(
    $fontName: "Opens Sans", 
    $fileName: "open-sans-v17-latin", 
    $weights: ("300","400", "700"), 
    $types: all
);

@include fontFaceV2(
    $fontName: "Stalemate", 
    $fileName: "stalemate-v7-latin", 
    $weights: ("400"), 
    $types: all
);