
// HEADER
//////////////////////////////

.headerTop {
	a.branding {
		display: none;
		@include breakpoint(huge) {
			display: block;
			max-width: rem(305px);
		}
		@include breakpoint(full) {
			max-width: rem(340px);
		}
	}
	@include breakpoint(giant) {
		position: absolute;
		width: 100%;
		background: $light;
		z-index: 999;
		box-shadow:0 0 rem(10px) rgba(black,0.1);
		.scroll & {
			animation: fadeDown 1500ms ease-in-out;
			top: 0;
			position: fixed;
	    }

		@keyframes fadeDown {
		  from {
		    top:-200px;
		    opacity:0;
		  }

		  to {
		     top:0;
		     opacity:1;
		  }
		}
	}
}

#header {
	position: relative;
	width: 100%;
	margin-top: rem(50px);
	@extend .marginBottomBig;
	@include breakpoint(giant) {
		margin-top: 0;
		padding-top: rem(155px);
	}
	@include breakpoint(huge) {
		padding-top: rem(199px);
	}
	@include breakpoint(full) {
		padding-top: rem(206px);
	}

	.bgHeader {
		height: rem(400px);
		@include responsiveImage('layout/bgHeader-neu.jpg', (tiny, large, huge), true);
		@include breakpoint(tiny) {
			background-position: right;
		}
		@include breakpoint(large) {
			background-position: center;
		}
		&.lazyLoaded {
			background-size:cover;

			@media screen and (min-width:1982px) {
				background-size:contain;
			}
		}
		body:not(.index) &{
			height: rem(120px);
			@include responsiveImage('layout/bgHeaderSub.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenSystemischesCoaching &{
			@include responsiveImage('layout/bgHeaderSystemischesCoaching.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenPsychologischeBeratung &{
			@include responsiveImage('layout/bgHeaderLeistungenPsychologischeBeratung.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenResilienzTraining &{
			@include responsiveImage('layout/bgHeaderLeistungenResilienzTraining.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenPartnerschaft &{
			@include responsiveImage('layout/bgHeaderLeistungenPartnerschaft.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenStaerkenCoaching &{
			@include responsiveImage('layout/bgHeaderLeistungenStaerkenCoaching.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		body.leistungenBuecherSelbstlernkurse &{
			@include responsiveImage('layout/bgHeaderLeistungenBuecherSelbstlernkurse.jpg', (huge), true);
			&.lazyLoaded {
				background-size:cover
			}
		}
		@include breakpoint(large) {
			height: rem(500px);
			body:not(.index) &{
				height: rem(150px);
			}
		}
		@include breakpoint(huge) {
			height: rem(600px);
			body:not(.index) &{
				height: rem(250px);
			}
		}
		@include breakpoint(full) {
			height: rem(750px);
		}
		>.row {
			height: 100%;
			>.col {
				height: 100%;
			}
		}
		.claimBox {
			height: 100%;
			display: flex;
			align-items: center;
			body:not(.index) &{
				display: none;
			}	
			div.mobile-claim{
				display: block;
				@include breakpoint(small) {
					display: inline;
				}	
			}
			.claim {
				display: block;
				font-family: $displayFont;
				font-size: rem(50px);
				color: $primary;
				line-height: rem(60px);
				margin-bottom: rem($baseGap);
				@include breakpoint(medium) {
					font-size: rem(70px);
					line-height: rem(90px);
				}
				@include breakpoint(giant) {
					font-size: rem(80px);
					line-height: rem(100px);
				}
				@include breakpoint(huge) {
					font-size: rem(90px);
					line-height: rem(150px);
					margin-bottom: rem($baseGap*2);
				}
				>span {
					margin-top: 5px;
					display: block;
					font-family:$mainFont;
					color: $dark;
					font-size: rem(18px);
					font-weight: 500;
					text-shadow: 0px 0px 8px white;
					letter-spacing: 0.1em;
					line-height: rem(22px);
					@include breakpoint(huge) {
						margin-top: 0px;
						font-size: rem(22px);
					}
				}
			}
			p {
				margin-bottom: rem(25px);
				font-weight: 500;
				text-shadow: 0px 0px 8px white;
				@include breakpoint(huge) {
					margin-bottom: rem(40px);
				}
			}
		} 
	}


	.branding {
		display: block;
		max-width: 280px;
		width: 100%;
		height: auto;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		padding: rem($baseGap) 0;
		@include breakpoint(tiny) {
			max-width: rem(300px);
		}
		@include breakpoint(large) {
			max-width: rem(350px);
		}
		@include breakpoint(huge) {
			display: none;
		}
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
}

// MAIN CONTENT
//////////////////////////////

body:not(.cmsBackend) .contentBox {
    z-index: 1;
    position: relative;
    padding:rem($baseGap*2); 
    margin:rem($baseGap*2) 0;
    img {
    	margin-bottom: rem($baseGap)*2;
    	@include breakpoint(large) {
    		margin-bottom: 0;
    	}
    }
    @include breakpoint(large) {
    	padding:rem($baseGap*3) rem($baseGap*4);
    }
    > *:last-child {
    	margin-bottom: 0;
    }
    &:before {
        content:'';
        background: $medium;
        position: absolute;
        top:0;
        left:0;
        right:0;
        margin:auto;
        width: calc(100% - #{rem($baseGap*2)}); 
        height: 100%;
        z-index: -1;
    }
    .btn {
    	margin-bottom: rem($baseGap);
    	display: table;
    	&:last-of-type {
    		margin-bottom: 0;
    	}
    }
    .highlight {
    	font-weight: 700;
    }
    &.partner {
    	color: $light;
    	&:before {
    		background: $secondary;
    	}	
    }
}

._form_14 {
	margin-bottom: rem($baseGap);
	>div {
		display: inline;
		form {
			margin: 0!important;
			padding: 0!important;
		}
	}
}

main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		.ueberMich & {
			margin-bottom: rem($baseGap*2);
			@include breakpoint(large) {
				margin-bottom: 0;
			}
		}
	}

	span.zitat, p.zitat {
		font-weight: 700;
		color: $primary;
	}

	._form_16 {
		display: block;
		form  {
			display: block;
		}
	}

	.teaser {
		@extend .marginBottomBig;
		position: relative;
		&:after {
			position: absolute;
			right: 45%;
			top: 104%;
			bottom: 0;
			font-size: rem(40px);
			font-family: $iconFont;
         	content: map-get($iconMap,  gesture-phone-02);
         	animation:bounce 1000ms alternate cubic-bezier(.5,0.05,1,.5) infinite;
         	@keyframes bounce { 
         	    from { transform: translate3d(-10px, 0, 0);}
         	    to   { transform: translate3d(10px, 0, 0); }
         	}
         	@include breakpoint(medium) {
         	 	content: map-get($iconMap, gesture-pad-05);
         	}
         	@include breakpoint(large) {
         	 	content: map-get($iconMap, gesture-pad-06);
         	}  
         	@include breakpoint(giant) {
         		display: none;
         	}
		}
		>.row {
		    @include breakpoint(giant, max) { 
				overflow-y: auto;
			    flex-wrap: nowrap;
			    display: flex;
			    width: 100%;
			    position: relative;
			    >.col {
			    	max-width: rem(380px);
			    }
		    }
		    @include breakpoint(giant) {
		    	>div {
		    		&:nth-child(1), &:nth-child(2), &:nth-child(3) {
		    			margin-bottom: rem(100px);
		    		}
		    	}
		    }
		}
		.box {
			max-width: rem(380px);
			margin: auto;
			text-align: center;
			.bg {
				height: rem(150px);
				width: rem(150px);
				background: $primary;
				border-radius: 100%;
				margin: 0 auto rem(25px) auto;
				@include breakpoint(tiny) {
					height: rem(180px);
					width: rem(180px);
					margin: 0 auto rem($baseGap*2) auto;
				}
				@include breakpoint(huge) {
					height: rem(220px);
					width: rem(220px);
				}
				&.system {
					&.lazyLoaded {
						background:image-url("index/teaser1.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.beratung {
					&.lazyLoaded {
						background:image-url("index/teaser2.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.training {
					&.lazyLoaded {
						background:image-url("index/teaser3.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.partnerschaft {
					&.lazyLoaded {
						background:image-url("index/teaser4.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.coaching {
					&.lazyLoaded {
						background:image-url("index/teaser5.jpg") center center no-repeat;
						background-size:cover;
					}
				}
				&.buecher {
					&.lazyLoaded {
						background:image-url("index/teaser6.jpg") center center no-repeat;
						background-size:cover;
					}
				}
			}
			.headlineTeaser {
				display: block;
				font-family: $displayFont;
				font-size: rem(40px);
				color: $primary;
				line-height: rem(50px);
				margin-bottom: rem(20px);
				@include breakpoint(huge) {
					font-size: rem(60px);
					line-height: rem(90px);
					margin-bottom: rem(30px);
				}
				>span {
					display: block;
					font-family:$mainFont;
					color: $dark;
					font-size: rem(18px);
					font-weight: 300;
					letter-spacing: 0.1em;
					line-height: rem(22px);
				}
			}
			p {
				margin-bottom: rem(25px);
				@include breakpoint(giant) {
					min-height: rem(100px);
				}
				@include breakpoint(huge) {
					margin-bottom: rem(40px);
				}
			}	
		}
	}
	.videoBg {
		@extend .marginBottomBig;
		@include breakpoint(large) {
		    padding: rem(65px) 0;
		    &.lazyLoaded {
		    	background:image-url("index/bgVideoBig.jpg") center center no-repeat;
		    	background-size: cover;
		    }
		  }
		  @include breakpoint(giant) {
		    padding: rem(85px) 0;
		  }
		  @include breakpoint(huge) {
		    padding: rem(100px) 0;
		  }
		  @include breakpoint(full) {
		    padding: rem(120px) 0;
		  }
		.claim {
			display: block;
			font-family: $displayFont;
			font-size: rem(50px);
			color: $primary;
			line-height: rem(60px);
			margin-bottom: rem($baseGap);
			@include breakpoint(small) {
				font-size: rem(70px);
				line-height: rem(90px);
			}
			@include breakpoint(large) {
				font-size: rem(44px);
				line-height: rem(64px);
			}
			@include breakpoint(giant) {
				font-size: rem(66px);
				line-height: rem(86px);
			}
			@include breakpoint(huge) {
				font-size: rem(76px);
				line-height: rem(96px);
				margin-bottom: rem($baseGap*2);
			}
			@include breakpoint(full) {
				font-size: rem(85px);
				line-height: rem(145px);
				margin-bottom: rem($baseGap*2);
			}
			>span {
				display: block;
				font-family:$mainFont;
				color: $dark;
				font-size: rem(18px);
				font-weight: 300;
				letter-spacing: 0.1em;
				line-height: rem(22px);
				@include breakpoint(huge) {
					font-size: rem(22px);
				}
			}
		}
		p {
			margin-bottom: rem(25px);
			@include breakpoint(huge) {
				margin-bottom: rem(40px);
			}
		}
		.videoBgMobil {
			padding-top: rem(30px);
			padding-bottom: rem(30px);
			@include responsiveImage('index/bgVideo.jpg', (tiny, large), true);
			&.lazyLoaded {
				background-size: cover;
				@include breakpoint(large) {
					background: none;
				}
			}
			@include breakpoint(tiny) {
				display: flex;
				justify-content: flex-end;
			}
			@include breakpoint(large) {
				padding-top: 0;
				padding-bottom: 0;
			}
			@include breakpoint(full) {
				display: block;
			}
		}
	}
}

.fastGeschafft {
	#header {
		margin-bottom: 0;
	}
	.nsTeser {
		display: none;
	}
	.bgHeader {
		display: none;
	}
	.geschafftBg {
		@include responsiveImage('layout/fast-geschafft/geschafftBg.jpg', (huge), true);
		&.lazyLoaded {
			background-size:cover;
		}
		.geschafftBox {
			@extend .paddingBoxBig;
			display: flex;
			align-items: center;
			justify-content: center;
			h1 {
				text-align: center;
				margin-bottom: 0;
				padding: rem($baseGap*2) rem($baseGap);
				background: rgba($secondary, 0.8);
				font-size: rem(70px);
				@include breakpoint(tiny) {
					padding: rem($baseGap*3) rem($baseGap*2);
				}
				@include breakpoint(medium) {
					padding: rem($baseGap*4) rem($baseGap*3);	
				}
				@include breakpoint(large) {
					padding: rem(100px) rem(70px);
				}
				span {
					color: $light;
				}
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

.flyer {
	max-height: 600px!important;
	margin: 50px;
}

body.schnupperEBook {
	a.btn {
		margin-top: rem(20px);
		@include breakpoint(medium) {
			margin-top: rem(20px);
		}
	}
}

#curator-box {
	background: $medium;
	padding: rem(20px);
	a.curator-btn {
		margin-bottom: rem(20px);
	}
	.dse-text {
		text-align: center;
	}
}

// FOOTER
//////////////////////////////

#footer {
	.nsTeser {
		background: $secondary;
		@extend .paddingBox;
		@extend .marginBottomBig;
		.newsletter {
			text-align: center;
			@include breakpoint(giant) {
				text-align: left;
			}
			.h1 {
				color: $light;
				span {
					color: $light;
				}
			}
		}
	}
	.googleMaps {
		@include breakpoint(large) {
			height: rem(400px);
		}
		@include breakpoint(huge) {
			height: rem(450px);
		}
	}
	@extend .marginTopBig;
	.info {
		text-align: center;
		margin-bottom: rem($baseGap*2);
		@include breakpoint(small) {
			display: flex;
			justify-content: space-around;
			text-align: left;
			margin: 0;
		}
		@include breakpoint(large) {
			justify-content: space-between;
		}
		>div {
			b {
				display: block;
				margin-bottom: rem(5px);
			}
			&:first-child {
				margin-bottom: rem($baseGap);
				@include breakpoint(small) {
					margin: 0;
				}
			}
		}
	}
	.hr {
		display: none;
		@include breakpoint(small) {
			display: block;
			margin: rem(20px) 0;
		}
		@include breakpoint(giant) {
			margin: rem(30px) 0;
		}
	}
	.footerBottom {
		@include breakpoint(giant) {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
		.social {
			text-align: center;
			margin-bottom: rem($baseGap	);
			@include breakpoint(large) {
				margin: 0;
			}
		}
		.naviAdd {
			display: none;
			@include breakpoint(giant) {
				display: flex;
				li {
					margin-left: rem(20px);
					@include breakpoint(huge) {
						margin-left: rem(30px);
					}
					@include breakpoint(full) {
						margin-left: rem(50px);
					}
					&.active {
						a {
							color: $primary;
						}
					}
					a {
						text-decoration: none;
						color: $dark;
						&:hover {
							color: $primary;
						}
					}
				}
			}
		}
	}
}

// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary;
$collapser-dark: $dark;

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb 78px $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: #fff;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {
}

h2.collapser {
	font-size: 1.1rem;
	@include breakpoint(small){
	font-size: 1.5625rem;
	}
}

._error-inner {
	color: #fff;
}

li.navItem.kooperationen {
	@include breakpoint(giant){
		display: none!important;	
	}
}

li.navItem.aktuellesPresse {
	@include breakpoint(giant){
		display: none!important;	
	}
}

li.navItem.anfahrt {
	@include breakpoint(giant){
		display: none!important;	
	}
}

li.navItem.agb {
	@include breakpoint(giant){
		display: none!important;	
	}
}

.navi.naviAdd {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}

.juic-app{
	iframe{
		&.social-feed{
			border: 0;
			width: 100%;
			height: 100vh;
		}
	}
}

.img-h{
	object-fit: cover;
	height: 45vh;
	width: auto;
	padding-bottom: 1rem;
	object-position: bottom;
	@include breakpoint(giant){
		height: auto;
		max-height: 38vh;	
	}
}


// Popup_____________________
.popUpContainer {
 
	body:not(.cmsBackend) & {
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		overflow: hidden;
		transition: opacity .3s, visibility .3s;
		position: fixed;
		visibility: visible;
		opacity: 1;
		z-index: 1000000;

		.popUpContent {
			width: 100%;
			background: white;
			max-height: 90%;
			max-width: calc(100% - #{rem(50px)}); // for the close label
			padding: 0.6rem 0.6rem 0.6rem 0.6rem;
	    	overflow: hidden;
			overflow-y: auto;
			border-radius: 1rem;
			line-height: 3rem;
			display: flex;
			flex-direction: column;

			@include breakpoint(giant) {
				max-width: 80%;
			}
	 
			@include breakpoint(full) {
				max-width: 70%;
			}
			.box{
				background-color: rgba(white, 0.65);
				padding: 1rem;
				border-radius: 1.3rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 0rem;

				.col{
					margin-bottom: 2rem;
				}

				p{
					color:rgb(67, 67, 67);
					font-size: 18px;
					font-weight: 280;
				}
			
				p.h2{
					color: #101010;
					font-size: 22px;
					transform: translate(20, 124);
					font-weight: 400;
				}
			}
		}
	}
 
	label {
		display:none;
 
		body:not(.cmsBackend) & {
			background: rgba(black, 0.8);
			display: flex;
			height: 100%;
			justify-content: flex-end;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: -1;
	 
			i {
				cursor: pointer;
				display: block;
				height: rem(35px);
				width: rem(35px);
				position: relative;
				transition: background .3s;

				background-color: $primary;
				border-radius: 0px 0px 0px 10px;
				font-weight: 300;
		    	color: black;
	 
				&:hover {
					background-color: darken($primary, 25%);
	 
					&:before, &:after {
						background: black;
					};
				}
	 
				&:before, &:after {
					content: '';
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					width: rem(15px);
					height: rem(1px);
					background: black;
				}
	 
				&:before {
					transform: translate(-50%,-50%) rotate(45deg);
				}
	 
				&:after {
					transform: translate(-50%,-50%) rotate(-45deg);
				}
			}
		}
	}
}
 
input[id^="popUpToggled"] {
	display: none;
 
	&:not(:checked) {
		+ {
			.popUpContainer {
				position: relative;
				opacity: 0;
				visibility: hidden;
				z-index: -1000000;
				transform:scale(0);
				pointer-events: none;
				width: 0;
				height: 0;
			}
		}
	}
}

.iframeHeight{
	height: 480px;
}
// _________________

.ueber-mich-bild {
	max-width: 400px!important;
}